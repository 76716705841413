import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import Spinner from '../layout/Spinner';
import { getUser } from '../../actions/users';
import { fetchPublicHolidays, submitLeaveApplication } from '../../actions/leave';

const LeaveForm = ({ getUser, fetchPublicHolidays, submitLeaveApplication, user, loading, publicHolidays }) => {
  const { id } = useParams(); // Retrieve the user ID from URL parameters
  
  // Fetch user data and public holidays when component mounts
  useEffect(() => {
    getUser(id);
    fetchPublicHolidays();
  }, [getUser, id, fetchPublicHolidays]);

  // State for form fields and calculations
  const [selectedOption, setSelectedOption] = useState('annual');
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');
  const [daysBetween, setDaysBetween] = useState('');
  const [reason, setReason] = useState('');
  const [halfDayOption, setHalfDayOption] = useState('');
  const [file, setFile] = useState(null); 
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [timeFrom, setTimeFrom] = useState('');
  const [timeTo, setTimeTo] = useState('');
  const [timeDifference, setTimeDifference] = useState({ hours: 0, minutes: 0 });

  useEffect(() => {
    setTimeDifference(calculateTimeDifference(timeFrom, timeTo));
  }, [timeFrom, timeTo]);

  // Check if a date is a weekend
  const isWeekend = (date) => {
    const day = date.getDay();
    return day === 6 || day === 0; // Saturday or Sunday
  };

  // Check if a date is a public holiday
  const isPublicHoliday = (date) => {
    return publicHolidays.some((holiday) => {
      const holidayDate = new Date(holiday.date);
      return holidayDate.toDateString() === date.toDateString();
    });
  };

  // Validate if a date is suitable for half-day leave
  const validateHalfDay = (date) => {
    return !(isWeekend(date) || isPublicHoliday(date));
  };

  // Calculate the number of working days between two dates
  const calculateDaysBetween = (fromDate, toDate) => {
    let startDate = new Date(fromDate);
    let endDate = new Date(toDate);
    
    // Ensure valid dates
    if (isNaN(startDate) || isNaN(endDate)) return '';
  
    if (startDate.toDateString() === endDate.toDateString()) {
      // Same date, check if it's a valid half-day
      if (validateHalfDay(startDate)) {
        return '0.5';
      }
      return '';
    }
  
    let days = 0;
    while (startDate <= endDate) {
      if (!isWeekend(startDate) && !isPublicHoliday(startDate)) {
        days++;
      }
      startDate.setDate(startDate.getDate() + 1);
    }

    // Check if the end date should be counted
    if (isWeekend(endDate) || isPublicHoliday(endDate)) {
      return days; // Return the count of valid working days
  }
  
    return days > 0 ? days - 1 : ''; // Adjust for inclusive end date
  };

  // Handle changes in date inputs
  const handleDateChange = (event) => {
    const { name, value } = event.target;

    if (name === 'dateFrom') {
      setDateFrom(value);
      if (dateTo) {
        const newDaysBetween = calculateDaysBetween(value, dateTo);
        setDaysBetween(newDaysBetween);
      }
    } else if (name === 'dateTo') {
      setDateTo(value);
      if (dateFrom) {
        const newDaysBetween = calculateDaysBetween(dateFrom, value);
        setDaysBetween(newDaysBetween);
      }
    }
  };

  // Handle changes in leave type radio buttons
  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  // Handle changes in reason textarea
  const handleReasonChange = (event) => {
    setReason(event.target.value);
  };

  // Handle changes in half-day option radio buttons
  const handleHalfDayChange = (event) => {
    setHalfDayOption(event.target.value);
    if (dateFrom === dateTo && validateHalfDay(new Date(dateFrom))) {
      setDaysBetween('0.5');
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]); // Get the first file
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true); // Set isSubmitting to true when submitting
  
    // Check if the selected leave type is "annual"
    if (selectedOption === 'annual') {
      const today = new Date();
      const leaveDate = new Date(dateFrom);
      const daysInAdvance = Math.ceil((leaveDate - today) / (1000 * 60 * 60 * 24));
  
      // Apply the 7-day rule for annual leave only
      if (daysInAdvance < 7) {
        alert('Please apply for annual leave at least 7 days in advance.');
        setIsSubmitting(false); // Reset submitting state on error
        return; // Prevent submission if the rule is violated
      }
    }
  
      // Create a FormData object to handle form data including the file
      const formData = new FormData();
      formData.append('user', user._id);
      formData.append('leaveType', selectedOption);
      formData.append('dateFrom', dateFrom);
      formData.append('dateTo', dateTo);
      formData.append('daysBetween', daysBetween);
      formData.append('reason', reason);
      formData.append('company', user.company?.companyName);
      formData.append('companyId', user.company._id);
      formData.append('name', user.name);
    
      if (daysBetween === '0.5') {
        formData.append('halfDayOption', halfDayOption);
      }

    // Add time range if applicable
    if (timeFrom && timeTo) {
      const [timeFromHours, timeFromMinutes] = timeFrom.split(':').map(Number);
      const [timeToHours, timeToMinutes] = timeTo.split(':').map(Number);

      const timeFromPeriod = timeFrom.split(' ').map(value => value.trim());
      const timeToPeriod = timeTo.split(' ').map(value => value.trim());
    
      // Send as flat fields
      formData.append('timeFromHours', timeFromHours);
      formData.append('timeFromMinutes', timeFromMinutes);
      formData.append('timeToHours', timeToHours);
      formData.append('timeToMinutes', timeToMinutes);
    }
    

      if (file) {
        formData.append('image', file); // Append the file if it exists
      }

      console.log(formData);
    
      // Submit leave application and handle response (not passing setIsSubmitting here)
      await submitLeaveApplication(formData);
    
      // Reset submission state after submission completes
      setIsSubmitting(false);
    };
  

  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) {
        return ''; // Or any other placeholder
    }

    return new Intl.DateTimeFormat('en-GB', { day: '2-digit', month: 'long', year: 'numeric' }).format(date);
};

const calculateTimeDifference = (timeFrom, timeTo) => {
  if (!timeFrom || !timeTo) {
    return { hours: 0, minutes: 0 }; // Return 0 if either time is missing
  }

  // Convert times to Date objects
  const fromTime = new Date(`1970-01-01T${timeFrom}:00`);
  const toTime = new Date(`1970-01-01T${timeTo}:00`);

  // Handle cases where timeTo is on the next day
  if (toTime < fromTime) {
    toTime.setDate(toTime.getDate() + 1);
  }

  // Calculate the difference in milliseconds
  const diff = toTime - fromTime;

  // Convert milliseconds to hours and minutes
  const hours = Math.floor(diff / (1000 * 60 * 60));
  const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

  return { hours, minutes };
};


  // Show spinner if data is loading or user is not available
  if (loading || !user) return <Spinner />;

  return (
    <Fragment>
      <div className="container roboto-regular">
        <div className="leaveform-wrapper">
          <div className="leaveform-header roboto-bold">Leave Application Form</div>
          <div className="leaveform-company-header">{user.company?.companyName}</div>
          <form className="leaveform-content-container" onSubmit={handleSubmit}>

            <div>Employee: {user.name}</div>
            <br/>

            <div>Type of Leave Applied: (Please select one)</div>

            <div className="radio-container">
              {['annual', 'medical', 'emergency', 'study', 'replacement', 'compassionate', 'marriage', 'maternity', 'hospitalisation', 'unpaid']
                .filter(type => {
                  // For users in probation, include only 'unpaid' and 'medical'
                  if (user.status === 'probation') {
                    return type === 'unpaid' || type === 'medical';
                  }
                  // For other users, include all leave types
                  return true;
                })
                .map((type) => (
                  <label key={type} className="leaveform-radio">
                    {type.charAt(0).toUpperCase() + type.slice(1).replace('isation', 'ization')} Leave
                    <input
                      type="radio"
                      name="leaveType"
                      value={type}
                      checked={selectedOption === type}
                      onChange={handleOptionChange}
                    />
                    <span className="checkmark"></span>
                  </label>
                ))}
            </div>

            <div className="laf-date">

              <div>
                <span>Date From:</span>
                <input
                    className="laf-input"
                    type="date"
                    name="dateFrom"
                    value={dateFrom}
                    onChange={handleDateChange}
                    required
                  />
              </div>

              <div>
                <span>Date To:</span>
                <input
                    className="laf-input"
                    type="date"
                    name="dateTo"
                    value={dateTo}
                    onChange={handleDateChange}
                    min={dateFrom}
                    required
                  />
              </div>

            </div>

            <div className="laf-date-resume">
              
            <div>
              {dateFrom !== dateTo && ( // Only render if dateFrom and dateTo are different
                <div>
                  <span>Resume Work: </span>
                  <span>{formatDate(dateTo)}</span>
              </div>
              )}
            </div>

            </div>

            {dateFrom && dateTo && dateFrom === dateTo && (
            <div className="laf-radio-container">
              {/* Render only one section based on the condition */}
              {user.company.usersLeaveRule.map((rule) => rule).includes(user._id) ? (
                
                <div className="time-selection-container">
                  <div className="ts-title">Time:</div>
                  <div className="ts-wrapper">

                    <label>
                      <span>From:</span>
                      <input
                        type="time"
                        name="timeFrom"
                        value={timeFrom}
                        onChange={(e) => setTimeFrom(e.target.value)}
                        className="ts-input"
                        required
                      />
                      
                    </label>
                    <label>
                      <span class="to-span">To:</span>
                      <input
                        type="time"
                        name="timeTo"
                        value={timeTo}
                        min={timeFrom}
                        onChange={(e) => setTimeTo(e.target.value)}
                        className="ts-input"
                        required
                      />
                    </label>

                  </div>
                </div>
              ) : (
<>
  <div>Select Half Day Option:</div>
  <br />
  {user.company._id === '670792445e884a458704edb6' ? (
    <>
      <label className="leaveform-radio">
        8:30am - 12:30pm
        <input
          type="radio"
          name="halfDayOption"
          value="8:30am-12:30pm"
          checked={halfDayOption === '8:30am-12:30pm'}
          onChange={handleHalfDayChange}
        />
        <span className="checkmark"></span>
      </label>
      <label className="leaveform-radio">
        1:30pm - 5:30pm
        <input
          type="radio"
          name="halfDayOption"
          value="1:30pm-5:30pm"
          checked={halfDayOption === '1:30pm-5:30pm'}
          onChange={handleHalfDayChange}
        />
        <span className="checkmark"></span>
      </label>
    </>
  ) : (
    <>
      <label className="leaveform-radio">
        9am - 1pm
        <input
          type="radio"
          name="halfDayOption"
          value="9am-1pm"
          checked={halfDayOption === '9am-1pm'}
          onChange={handleHalfDayChange}
        />
        <span className="checkmark"></span>
      </label>
      <label className="leaveform-radio">
        2pm - 6pm
        <input
          type="radio"
          name="halfDayOption"
          value="2pm-6pm"
          checked={halfDayOption === '2pm-6pm'}
          onChange={handleHalfDayChange}
        />
        <span className="checkmark"></span>
      </label>
    </>
  )}
</>
              )}
            </div>
          )}

        <div>
        {timeFrom && timeTo ? (
        // Show Time Difference if time range is selected
        <div className="lf-duration">
          Duration: {timeDifference.hours} hours and {timeDifference.minutes} minutes
        </div>
        ) : halfDayOption ? (
        // Show Number of Days if half-day option is selected
        <div className="laf-textbox">
          <span>Number of Days:</span>
          <input
            className="laf-input"
            type="text"
            name="daysBetween"
            value={daysBetween}
            readOnly
          />
        </div>
        ) : !timeFrom && !timeTo && !halfDayOption ? (
        // Show Number of Days if neither time range nor half-day option is selected
        <div className="laf-textbox">
          <span>Number of Days:</span>
          <input
            className="laf-input"
            type="text"
            name="daysBetween"
            value={daysBetween}
            readOnly
          />
        </div>
        ) : (
        // Fallback if none of the conditions are met
        <div></div>
        )}
        </div>

              
            <div className="laf-textbox">
              <span>Reason</span>
              <textarea
                  className="laf-input custom-textarea"
                  name="reason"
                  value={reason}
                  onChange={handleReasonChange}
                  required
                />
            </div>

            <div className="laf-file-upload">
              <label>Attach Supporting Document (if any): &nbsp;</label>
              <input 
                type="file"
                name="image" 
                onChange={handleFileChange} 
              />
            </div>

            <br/>

            {daysBetween > 0 && (
              <button type="submit" className="submit-btn roboto-bold" disabled={isSubmitting}>{isSubmitting ? 'Submitting...' : 'Submit'}</button>
            )}

          </form>
        </div>
      </div>
    </Fragment>
  );
};

LeaveForm.propTypes = {
  getUser: PropTypes.func.isRequired,
  fetchPublicHolidays: PropTypes.func.isRequired,
  submitLeaveApplication: PropTypes.func.isRequired,
  user: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  publicHolidays: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.users.user,
  loading: state.users.loading,
  publicHolidays: state.leave.publicHolidays,
});

export default connect(mapStateToProps, { getUser, fetchPublicHolidays, submitLeaveApplication })(LeaveForm);